<template>
  <div>
    <HeaderPanel
      title="Social Media"
      :filter="filter"
      placeholder="Search Telephone, Ref. Code"
      @search="getList"
      :hasDropdown="false"
      :hasFilter="false"
      :hasSearch="false"
      routerPath="/setting/socialmedia/0"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(banner)="{ item }">
              <b-img
                :src="item.banner || default_image"
                class="banner-image"
                @error="handleImageSrc"
              ></b-img>
            </template>
            <template v-slot:cell(action)="{ item }">
              <router-link :to="'socialmedia/' + item.id">
                <b-button variant="link" class="text-warning px-1 py-0">
                  <font-awesome-icon icon="pencil-alt" title="Edit" />
                </b-button>
              </router-link>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
    </div>

    <router-view></router-view>
    <FooterAction routePath="/setting" :hideSubmit="true" />
  </div>
</template>
<script>
import HeaderPanel from "@/components/HeaderPanel";

export default {
  name: "age_Group",
  components: {
    HeaderPanel,
  },
  data() {
    return {
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      fields: [
        {
          key: "banner",
          label: "Image",
        },
        {
          key: "name",
          label: "Name",
          class: "text-center",
        },

        { key: "action", label: "" },
      ],
      items: [],
    };
  },
  created() {
    this.getList();
  },

  methods: {
    async getList() {
      this.isBusy = true;
      const response = await this.axios.post(
        `/setting/GetSocialMedia`,
        this.filter
      );

      this.rows = response.data.detail.count;
      this.items = response.data.detail.data;
      this.isBusy = false;
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
  },
};
</script>
<style lang="scss">
.banner-image {
  max-width: 50px;
  height: auto;
  width: 100%;
  aspect-ratio: 1;
}
</style>
